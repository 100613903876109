<template>
    <div class="columns is-mobile ml-0" id="nav-escritorio" style="background-color: #008575">
        <div v-show="!isMobile" class="column has-text-right tags has-addons mt-4  py-0 px-0">
                    <span  class="tag ">Mi licencia</span>
                    <span  class="tag has-background-primary-dark has-text-white">{{anuncios}} Anuncios y {{puntos}} Puntos Wifi</span>
                    <!-- <span v-if="licencia==''" class="tag has-background-primary-dark has-text-white">No posee licencia</span>
                    <span v-else class="tag has-background-primary-dark has-text-white">{{licencia}}</span> -->
        </div>

        <div v-show="!isMobile" class="column has-text-right  mt-1 is-flex-grow-0">
                <!-- <label class="label is-small verde-puwic has-text-white mr-1">Mi licencia: hasta 5 anuncios </label> -->
                <a href="https://forms.monday.com/forms/1082d6e7a04de0ed35468c3d3d212945?r=use1" target="_blank" class="button is-small  verde-puwic has-text-white mr-1">Actualizar licencia</a>
        </div>
        <!-- <div v-show="isMobile" class="column has-text-center   ml-3  has-addons  ">
                 <button class=" button is-small verde-puwic has-text-white mr-1">Mi licencia: hasta 5 anuncios</button>
                <a href="https://forms.monday.com/forms/1082d6e7a04de0ed35468c3d3d212945?r=use1" class="button is-small  verde-puwic has-text-white mr-1">Actualizar licencia</a>

        </div> -->

        <div v-show="isMobile" class="column  ">
             <div   v-show="isMobile"  class="column has-text-center tags has-addons">
                <span class="tag">Mi licencia</span>
                <span class="tag has-background-primary-dark has-text-white">{{anuncios}} Anuncios y {{puntos}} Puntos Wifi</span>
                <!-- <span v-if="licencia==''" class="tag has-background-primary-dark has-text-white">No posee licencia</span>
                <span v-else class="tag has-background-primary-dark has-text-white">{{licencia}}</span> -->

            </div>
        </div>
        <div class="column is-flex-grow-0">
            <div id="logo-desplegable" class="buttons is-justify-content-flex-end">
                <!-- <div> -->
                    <!-- <a class="mr-5 mt-1">
                            <span class="icon  has-text-dark is-size-5 texto">
                        <i class="fas fa-bell"></i>
                        </span>
                    </a> -->
                    <!-- <a class="mr-4 mt-1" >
                        <span class="icon  has-text-dark is-size-5 texto " >

                        <i class="fas fa-info-circle"></i>
                        </span>
                    </a> -->
                <!-- </div> -->

                <div id="logo-desplegable" class="dropdown is-right mt-2" :class="{'is-active':opcion}">
                    <div id="logo-desplegable" class="dropdown-trigger">
                        <a id="logo-desplegable" class="ml-2 mt-2 mr-3 " @click="opcion = !opcion">
                            <span id="logo-desplegable" class="icon  has-text-dark is-size-3 texto">
                                <i id="logo-desplegable" class="fas fa-user-circle "></i>
                            </span>
                        </a>
                    </div>
                    <div class="dropdown-menu desplegable" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a href="#" class="dropdown-item pr-0">
                                Saludo {{nombre}}
                            </a>
                            <!-- <router-link class="dropdown-item" @click="opcion = false" :to="{name: 'Mi Perfil'}">Mi perfil</router-link> -->
                            <a class="dropdown-item" @click="verificarHabilitado('Mi Perfil')">Mi perfil</a>
                            <a v-show="isMobile" class="dropdown-item mr-1 pr-0" href="https://forms.monday.com/forms/1082d6e7a04de0ed35468c3d3d212945?r=use1" target="_blank">
                               Actualizar licencia
                            </a>
                            <a class="dropdown-item pr-0" @click="opcion = false" href="https://forms.monday.com/forms/43d3a6d357e283d2dcb7d70feb661f29?r=use1" target="_blank">
                                Darme de baja
                            </a>
                            <a href="javascript:location.reload()" class="dropdown-item pr-0" @click="cerrarSesion">
                                Cerrar sesion
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { useRouter } from "vue-router";
import { inject, ref, watchEffect } from 'vue';
import store from '@/store';
import { GraphQLClient } from 'graphql-request';
import {queryMe} from '@/composables/queryMe.js'

export default {
    name: 'Navbar',
    data:()=>({
        msg:'hola'
    }),

    setup(){
        let opcion = ref(false)
        const isMobile = inject('isMobile')
        const router = useRouter();
        const licencia = ref('5 Anuncios y 1 Punto Wifi')
        const anuncios = ref(0)
        const puntos = ref(0)
        const endpoint = store.state.url_backend
        let nombre = localStorage.getItem('username')

        const cerrarSesion = () => {
            localStorage.clear()
            // location.reload()

            router.push({name: 'Login'})
        }

        const verificarHabilitado = async (name) => {
            opcion.value = false
            let habilitado = await queryMe()
            // console.log(name)
            if (habilitado) {
                router.push({name: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        document.addEventListener('click', function(e) {
            let clic = e.target.getAttribute("id")

            if ((clic != "logo-desplegable") && (e.path[1].getAttribute("id") != "logo-desplegable")) {
                opcion.value = false
            }
        }, false)

        const traerLicencia = () =>{
               watchEffect(() => {
                const client = new GraphQLClient(endpoint)

                client.rawRequest(/* GraphQL */ `
                  query {
                        me {
                            empresa {
                            id
                            habilitada
                            licencia {
                                id
                                puntos
                                anuncios
                            }
                            }
                        }
                        }`,
                    {

                    },
                     {
                        authorization: `Bearer ${ localStorage.getItem('user_token') }`
                    })
                    .then((data) => {

                     let me = data.data.me

                        //  console.log(me)

                       licencia.value = me.empresa.licencia
                       anuncios.value = me.empresa.licencia.anuncios
                       puntos.value = me.empresa.licencia.puntos




                         if(licencia.value==null){
                             licencia.value =''
                         }

                        //    console.log(licencia.value)
                         }).catch(error => {
                        //  console.log(error.response);
                     })
                })}

        return{
            cerrarSesion,
            opcion,
            isMobile,
            nombre,
            licencia,
            traerLicencia,
            anuncios,
            puntos,
            verificarHabilitado
        }
    },
    created(){
        this.traerLicencia()

    },
}
</script>

<style scoped>
.texto:hover{
    /* background-color: #C6C3C2; */
    box-shadow: 0 12px 18px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    border: none;
}

.desplegable {
    min-width: 130px;
}

@media screen and (min-width: 700px){
    #nav-escritorio{
        padding-top: 8px;
    }
}

</style>