<template>

    <div class="column has-background-white mt-2 pl-4">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li v-for="item in items" :key="item.path">
                    <router-link v-if="item.meta.value == 'Sistema'" to=""> {{item.meta.value}} </router-link>
                    <a v-else-if="item.meta.value == 'Inicio'" @click="desactivar">{{item.meta.value}}</a>
                    <!-- <router-link v-else :to="item.path"> {{item.meta.value}} </router-link> -->
                    <a v-else @click="verificarHabilitado(item.path)">{{item.meta.value}}</a>
                </li>
            </ul>
        </nav>
    </div>

</template>

<script>
import store from '@/store';
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import {queryMe} from '@/composables/queryMe.js'

export default {
    name: 'Breadcrumbs',

    watch: {
        $route(){
            this.getRoute()
        }
    },

    setup(){
        const items = ref([])
        const listas = store.state.listas
        const route = useRoute()
        const router = useRouter()

        const verificarHabilitado = async (name) => {
            let habilitado = await queryMe()
            // console.log(name)
            if (habilitado) {
                router.push({path: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        const getRoute = () => {
            items.value = []
            route.matched.forEach(element => {
                if (element.meta.value) {
                    items.value.push(element)
                    // console.log(element)
                }
            })
            // console.log(items.value)
        }

        const desactivar = () => {
            listas.forEach(element => {
                element.activo = false
                if (!element.link) {
                    element.opc.forEach(it => {
                        it.activo = false
                    })
                }
            })

            verificarHabilitado('/Tablero')
            // router.push({name: 'Tablero'})
        }

        return{
            items,
            listas,
            getRoute,
            desactivar,
            verificarHabilitado
        }
    },

    created() {
        this.getRoute();
    },

}
</script>

<style>

</style>