<template>
    <div class="columns has-background-white-ter" style="min-height: 700px" >

        <Menu/>
        <div class="column pl-0">

            <Navbar/>

            <Breadcrums/>

            <router-view></router-view>
        </div>
    </div>

</template>

<script>
import Menu from '../components/MenuPrincipal/Menu'
import Navbar from '../components/MenuPrincipal/Navbar'
import Breadcrums from '../components/MenuPrincipal/Breadcrums'

export default {

    components: {
        Menu,
        Navbar,
        Breadcrums
    },

    methods: {
        recargar(){


            if( window.localStorage )
            {
                if( !localStorage.getItem('firstLoad') )
                {
                localStorage['firstLoad'] = true;
                window.location.reload();
                }
                else{
                localStorage.removeItem('firstLoad');
            }
            }

        },
        home(){
            // window.location.reload()
            if (this.$route.name == 'Home' || this.$route.path == '/Home') {
                this.$router.push({name: 'Tablero'})
            }
        }
    },

    created(){
        this.home()
        this.recargar()
        // var token = localStorage.getItem('user_token')
        // var username = localStorage.getItem('username')
        // console.log('user',username)
        // console.log('token',token)



    }

}
</script>

<style scoped>
.texto:hover{
    background-color: #C6C3C2;
}







</style>