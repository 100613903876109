<template>
     <div v-show="!isMobile" class="column is-narrow has-background-white" id="escritorio">
        <div>
            <!-- <router-link :to="{name:'Tablero'}"> <img   width="200"  height="100" src="@/assets/logotipo-PuWiC.png" alt="logo PuWiC"> </router-link> -->
            <a @click="verificarHabilitado('Tablero')">
                <img   width="200"  height="100" src="@/assets/logotipo-PuWiC_sys3.png" alt="logo PuWiC">
                </a>
        </div>
        <aside>
            <ul class="menu-list">
                <li v-for="lista in listas" :key="lista.nombre">

                    <!-- boton para los que no tienen lista desplegable -->
                    <a v-show="lista.link" class="texto" @click="next(lista)">
                        <span class="icon-text">
                            <span class="icon">
                                <i :class="[lista.icon]"></i>
                            </span>
                            <span class="ml-2" :class="{'texto-puwic': lista.activo}">{{lista.nombre}}</span>
                        </span>
                    </a>

                    <!-- boton para los que tienen lista desplegable -->
                    <a v-show="!lista.link" class="texto" @click="activar(lista)">
                        <span class="icon-text">
                            <span class="icon">
                                <i :class="[lista.icon]"></i>
                            </span>
                            <span class="ml-2" :class="{'texto-puwic': lista.activo}">{{lista.nombre}}</span>
                        </span>
                    </a>

                    <!-- lista desplegada -->
                    <ul v-show="lista.activo && !lista.link">
                        <li v-for="item in lista.opc" :key="item.nombres">
                            <!-- <router-link :to="{name: item.name_link}" class="texto">{{item.nombre}}</router-link> -->
                            <a class="texto" :class="{'texto-puwic':item.activo}" @click="subnext(lista, item)">{{item.nombre}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </aside>
    </div>
    <div v-show="isMobile" >
        <nav class="navbar mt-2 " role="navigation" aria-label="main navigation">
            <div class="navbar-brand ">
                <!-- <router-link :to="{name:'Tablero'}"><img class="img2" width="200"  height="100" src="@/assets/logotipo-PuWiC.png" alt="Img PuWiC"></router-link> -->
                <a @click="verificarHabilitado('Tablero')"><img class="img2 ml-3 mt-2" src="@/assets/logotipo-PuWiC_sys3.png" alt="Img PuWiC"></a>
                <a role="button" class="navbar-burger  mt-3 mr-2 is-align-self-center" aria-label="menu" aria-expanded="false" @click="isOpen = !isOpen" :class="{'is-active': isOpen}">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div class="navbar-menu" :class="{'is-active': isOpen}">
                <ul class="menu-list">
                    <li v-for="lista in listas" :key="lista.nombre">

                        <a v-show="lista.link" class="texto ml-3" @click="next(lista)">
                            <span class="icon-text">
                                <span class="icon">
                                    <i :class="[lista.icon]"></i>
                                </span>
                                <span class="ml-2" :class="{'texto-puwic': lista.activo}">{{lista.nombre}}</span>
                            </span>
                        </a>

                        <a v-show="!lista.link" class="texto ml-3" @click="activar(lista)">
                            <span class="icon-text">
                                <span class="icon">
                                    <i :class="[lista.icon]"></i>
                                </span>
                                <span class="ml-2" :class="{'texto-puwic': lista.activo}">{{lista.nombre}}</span>
                            </span>
                        </a>

                        <ul v-show="lista.activo && !lista.link">
                            <li v-for="item in lista.opc" :key="item.nombres">
                                <a class="texto" :class="{'texto-puwic':item.activo}" @click="subnext(lista, item)">{{item.nombre}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import store from '@/store';
import { useRouter } from 'vue-router';
import { ref } from '@vue/reactivity';
import { inject } from '@vue/runtime-core';
import {queryMe} from '@/composables/queryMe.js'


export default {
    name: 'Menu',

    setup(){
        const listas = store.state.listas
        const router = useRouter()
        const isMobile = inject('isMobile')
        const isOpen = ref(false)

        const verificarHabilitado = async (name) => {
            let habilitado = await queryMe()
            // console.log(name)
            if (habilitado) {
                router.push({name: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        const activar = (lista) => {
            listas.forEach(element => {
                if (lista != element){
                    element.activo = false
                }
                if (!element.link) {
                    element.opc.forEach(it => {
                        it.activo = false
                    })
                }
            });
            lista.activo = !lista.activo
        }

        // cambia de pagina de forma fluida
        const next = (lista) => {
            activar(lista)
            // router.push({name: lista.name_link})
            verificarHabilitado(lista.name_link)
        }

        // Es para el submenu
        const subnext = (lista, item) => {
            listas.forEach(element => {
                if (lista != element){
                    element.activo = false
                }
                if (!element.link) {
                    element.opc.forEach(it => {
                        it.activo = false
                    })
                }
            });
            item.activo = !item.activo
            verificarHabilitado(item.name_link)

            // router.push({name: item.name_link})
        }
        return{
            listas,
            next,
            activar,
            subnext,
            isMobile,
            isOpen,
            verificarHabilitado
        }
    }
}
</script>

<style scoped>
.texto:hover{
    box-shadow: 0 12px 18px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    border: none;
}

.texto-puwic{
  color: #008575;
}

@media screen and (min-width: 700px) {
    #escritorio{
    width: 240px;
  }
}

  .img2{
    width: 230px;
  }

@media screen and (min-width: 370px) {

    .img{
    width: 300px;

  }
}
</style>